import LaunchIcon from "@mui/icons-material/LaunchOutlined";
import { Box, Link } from "@mui/material";

interface Props {
  url?: string | null;
  description?: string | null;
  addMargin: boolean;
  onClick: () => void;
}

const KpiLink: React.FC<Props> = ({ url, description, addMargin, onClick }) => {
  return (
    <Box mt="auto">
      {url && (
        <Link
          variant="body2"
          sx={{
            display: "flex",
            gap: 0.5,
            mb: addMargin ? 0.5 : 0,
            flexWrap: "wrap",
            color: "component.links",
            mt: 1,
            fontSize: "1rem",
            textDecoration: "underline",
            "&:hover": {
              fontWeight: 500,
              color: "primary.dark"
            }
          }}
          href={url}
          onClick={onClick}
        >
          <span
            style={{
              display: "table-cell",
              verticalAlign: "middle",
              textDecoration: "underline"
            }}
          >
            {url.includes("/") ? <LaunchIcon fontSize="small" sx={{ mr: 1, mb: "-5px" }} /> : null}
            {description ? description : "View more data"}
          </span>
        </Link>
      )}
    </Box>
  );
};

export default KpiLink;
