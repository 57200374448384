import { useMemo } from "react";
import dynamic from "next/dynamic";

import { ChartFallback } from "common/components/charts/Chart/Chart";
import { TrendlineProps } from "common/components/charts/Trendline";

// dynamic component to get so highcharts resizes correctly
export const DynamicTrendLineChart: React.FC<TrendlineProps> = (props) => {
  const Component = useMemo(
    () =>
      dynamic(async () => import("common/components/charts/Trendline"), {
        loading: () => <ChartFallback />,
        ssr: false
      }),
    []
  );
  return <Component {...props} />;
};
