import { KpiPropsAndRelatedKpis } from "./types";
import { MhcTimeSeriesGranularityEnum } from "graphqlApi/types";

export function getSmallestGranularity(granularities: (MhcTimeSeriesGranularityEnum | null)[]) {
  const granularitiesOrderMap = {
    [MhcTimeSeriesGranularityEnum.Day]: 0,
    [MhcTimeSeriesGranularityEnum.Week]: 1,
    [MhcTimeSeriesGranularityEnum.Month]: 2,
    [MhcTimeSeriesGranularityEnum.Quarter]: 3,
    [MhcTimeSeriesGranularityEnum.Year]: 4,
    [MhcTimeSeriesGranularityEnum.FiveYearWindow]: 5
  };
  const r = granularities.sort((a, b) =>
    a && b ? granularitiesOrderMap[a] - granularitiesOrderMap[b] : 0
  );
  if (r.length > 0) return r[0];
  return null;
}

// TODO: Could probably use some refactoring to use the stat type field instead of the id.
export function getStatTypeByStatId(statId: string, isAgeAdjusted: boolean, isPercent: boolean) {
  if (statId.endsWith("_DOLLARS")) return "Amount";
  if (statId.endsWith("_PERCENT") || isPercent === true) return "Percentage";
  if (statId.endsWith("_COUNT")) return "Count";
  if (statId.endsWith("_CRUDE_RATE")) return "Crude Rate";
  if (isAgeAdjusted === true) return "Age Adjusted Rate"; // Note: Not all SIs ending in _RATE are age adjusted
  if (statId.endsWith("_RATE")) return "Rate";
  if (statId.endsWith("_PERCENT")) return "Percent";
  return "";
}

export const updateKpiTitlesWithTheSameName = (
  kpis: KpiPropsAndRelatedKpis[]
): KpiPropsAndRelatedKpis[] => {
  const ids = kpis.reduce((filtered, { id }) => {
    if (id) {
      const split = id.split("_").slice(0, -1);
      filtered.push(split.join("_"));
    }
    return filtered;
  }, [] as string[]);
  const duplicates = ids.filter((item, index) => ids.indexOf(item) !== index);

  const updatedKpis = kpis.map((kpi) => {
    const id = kpi.id?.split("_").slice(0, -1).join("_");
    if (id && duplicates.includes(id) && typeof kpi.title === "string") {
      const statType = getStatTypeByStatId(
        kpi.id ?? "",
        kpi.ageAdjusted ?? false,
        kpi.isPercent ?? false
      );
      if (statType === kpi.title) {
        return kpi;
      }
      return {
        ...kpi,
        title: `${kpi.title} (${statType})`
      };
    }
    return kpi;
  });
  return updatedKpis;
};
